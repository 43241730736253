.summary {
    padding: 2rem;
    border: 1px solid #b5b5b5;
    border-radius: 12px;
    margin-top: 50px;
    box-shadow: 3px 3px 3px #b5b5b5;
}

.summary p{
    margin-top: 20px;
}