.inactiveUserAlert {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    text-align: center;
}

.inactiveUserAlert .lock {
    font-size: 54px;
    color: salmon;
}

.inactiveUserAlert a {
    /* color: blue; */
    text-decoration: underline;
}