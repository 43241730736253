.footer {
    background-color: var(--white);
    padding: 0px 16%;
    margin-top: 100px;
}

.footer .topField {
    display: flex;
    padding: 80px 0;
}

.footer .field {
    flex: 1;
}

.footer .field h4 {
    color: var(--black);
    margin-bottom: 12px;
}

.footer .field1 .logo img{
    width: 60%;
}

.footer .field ul {
    list-style: none;
    padding: 0;
}

.footer .field ul li {
    margin: 10px 0;
    color: var(--darkGray);
    overflow-wrap: break-word;
    font-size: 14px;
    width: 60%;
}

.footer .field ul li a {
    text-decoration: none;
    color: var(--darkGray);
}

.footer .field.field3 li {
    cursor: pointer;
    width: fit-content;
}

.footer .bottomField {
    border-top: 1px solid rgb(200, 200, 200);
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
}

.footer .bottomField span {
    color: var(--darkGray);
    font-size: 12px;
}

.footer .bottomField .socialMedia {
    display: flex;
    gap: 20px;
}

.footer .bottomField .socialMedia a {
    color: var(--black);
    text-decoration: none;
}

.footer .bottomField .socialMedia a:hover {
    scale: 1.2;
}

@media (max-width: 768px) {
    .footer {
        margin-top: 40px;
    }

    .footer .topField {
        flex-direction: column;
        gap: 50px;
        align-items: center;
        text-align: center;
    }

    .footer .field ul li {
        width: 100%;
    }

    .footer .bottomField {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
}