.price h2 {
    margin-bottom: 10px;
    color: #333;
}

.price .body{
    display: flex;
    align-items: center;
    gap: 50px;
    font-size: 17px;
    border: 1px solid var(--teal);
    width: fit-content;
    padding: 1rem 2rem;
    border-radius: 8px;
}

.price .body .splitLine {
    width: 1px;
    height: 30px;
    background-color: var(--teal);
}

.price .body .throughLine {
    text-decoration: line-through;
}

.ReactModal__Content .priceModalContent div{
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.ReactModal__Content .priceModalContent div:not(:first-child){
    margin-top: 20px;
}

.ReactModal__Content .priceModalContent div label {
    text-align: end;
}

.ReactModal__Content div input[type=number] {
    width: 50%;
}

@media (max-width: 768px) {
    .price .body {
        flex-direction: column;
        margin: 0 auto;
        gap: 20px;
    }

    .price .body .splitLine {
        width: 30%;
        height: 1px;
    }
}
