.page404{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 100px;
}

.page404 p{
    color: var(--darkGray);
}