.setPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    gap: 40px;
    padding: 5rem;
}

.setPassword .error {
    color: red;
    margin-top: -20px;
    margin-left: 50px;
    word-break: break-all;
    align-self: flex-start;
    word-break: break-all;
    max-width: 280px;
}

@media (max-width: 576px) {
    .setPassword{
        padding: 3rem 0;
    }
}