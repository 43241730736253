.signUp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    gap: 40px;
    padding: 4rem;
}

.signUp .error {
    color: red;
    margin-top: -20px;
    margin-left: 50px;
    word-break: break-all;
    align-self: flex-start;
    word-break: break-all;
    max-width: 280px;
}

.signUp .checkmark {
    color: blue;
    cursor: pointer;
}

.signUp .policies {
    display: flex;
    align-items: center;
    margin-left: 45px
}

@media (max-width: 576px) {
    .signUp{
        padding: 3rem 0;
    }
}