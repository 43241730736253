.banner {
    width: 100vw;
    height: 420px;
    background: linear-gradient(-45deg, lightblue, #afafaf);
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: space-around;
    position: relative;
    overflow: hidden;
    padding: 0 16%;
}

.banner::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80%;
    height: 40%;
    background: rgba(7, 7, 7, 0.1);
    z-index: 1;
    -webkit-transform: skewY(-5deg) scale(1.5);
    transform: skewY(-5deg) scale(1.5);
}

.banner .leftSide {
    z-index: 2;
    color: #fff;
}

.banner .leftSide .badgeText {
    padding: 4px 8px;
    background-color: rgba(7, 7, 7, 0.1);
    border-radius: 8px;
}

.banner .leftSide .btn {
    display: inline-block;
    margin-top: 10px;
}

.banner .rightSide {
    width: 30%;
    z-index: 2;
}

.banner .rightSide .bannerImages {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.banner .rightSide .bannerImages img {
    width: 180px;
    height: auto;
    object-fit: cover;
    border-radius: 20px;
    transition: all 0.4s ease;
}

.banner .rightSide .bannerImages img:hover {
    margin-bottom: 30px;
}

.banner .rightSide .bannerImages img:first-child{
    margin-top: -32px;
}

.banner .rightSide .bannerImages img:last-child{
    margin-top: 32px;
}

@media (max-width: 1500px) {
    .banner .rightSide .bannerImages img {
        width: 164px;
    }
}

@media (max-width: 1400px) {
    .banner .leftSide p {
        width: 70%;
    }

    .banner .leftSide h1 {
        width: 70%;
    }
}

@media (max-width: 1200px) {
    .banner .rightSide {
        width: 50%;
    }
}

@media (max-width: 992px) {
    .banner {
        height: 720px;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
    }

    .banner .rightSide {
        width: fit-content;
    }
    .banner .leftSide p, .banner .leftSide h1 {
        width: 100%;
    }
}