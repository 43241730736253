@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Titillium Web', sans-serif;
}

:root {
    --black: #000;
    --white: #fff;
    --darkGray: #939393;
    --gray: #d0d0d0;
    --lightGray: #f7f7f7;
    --teal: #162a2f;
}

.appContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    position: relative;
}

.btn {
    padding: 8px 28px;
    background-color: var(--black);
    border: 2px solid var(--black);
    color: var(--white) !important;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.5s;
}

.btn::after {
    display: block;
    content: '';
    border-bottom: solid 2px var(--white) !important;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}

.btn.outlineBtn {
    background-color: var(--white);
    color: var(--black) !important;
}

.btn.outlineBtn::after {
    border-bottom: solid 2px var(--black) !important;
}

.btn:not(:disabled):hover::after {
    transform: scaleX(1);
}

.btn i {
    margin-right: 12px;
}

.modalButtons {
    display: flex;
    gap: 20px;
}

.modalButtons .deleteButton {
    background-color: salmon;
    border-color: salmon;
}

a:-webkit-any-link {
    text-decoration: none;
    color: var(--black);
}

.decorationText {
    text-decoration: underline !important;
    font-weight: bold;
}

[type="checkbox"] {
    margin-right: 10px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    background-color: white;
    padding: 32px;
    border-radius: 20px;
    box-shadow: 8px 8px 4px gray;
    width: 420px;
}

.form div {
    width: 100%;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="password"] {
    padding: 12px 50px;
    border: 1px solid var(--darkGray);
    border-radius: 16px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 5px 5px 2px gray;
}

.form .iconInput {
    position: relative;
}

.form .iconInput i:not(.password) {
    position: absolute;
    top: 35%;
    left: 6%;
    cursor: pointer;
}

.form .iconInput .password {
    position: absolute;
    top: 35%;
    right: 4%;
    cursor: pointer;
}

.form select:focus,
.form input:focus {
    outline: none;
}

.form .lineText {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form .lineText .line {
    position: relative;
    height: 1px;
    background-color: var(--gray);
}

.form .lineText .text {
    margin: 0;
    padding: 0;
    text-align: center;
    color: var(--darkGray);
}

.form .bottomPreferences {
    display: flex;
    justify-content: space-between;
}

.form .logoLink {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form .logoLink img {
    width: 240px;
}

.form .disabledBtn {
    cursor: not-allowed;
    opacity: 0.5;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -999;
}

.background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
}

.iconBtn {
    padding: 4px 6px;
    border: none;
    border-radius: 6px;
    background: transparent;
    cursor: pointer;
    font-size: 16px;
}

.deleteBtn i {
    color: salmon !important;
}

.accordion {
    display: flex;
    flex-direction: column;
}

.accordion .accordionBox {
    padding: 16px;
    border: 1px solid var(--gray);
    border-radius: 12px;
    margin-bottom: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.accordion .accordionHeader {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    padding: 12px 6px;
}

.accordion .accordionHeader i {
    color: rgb(0, 128, 255);
    background-color: rgba(30, 144, 255, 0.15);
    border-radius: 6px;
    padding: 6px 12px;
}

.accordion .content {
    padding: 30px 12px;
}

.accordion .accordionBox p {
    margin: 8px 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 5.5em;
    height: 5.5em;
    border: 0.9em solid rgba(0, 0, 0, 0.2);
    border-left: 0.9em solid #000000;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
    z-index: 999999;
}

.loader--hide {
    display: none;
}

.fullScreenOpacity {
    opacity: 0.7;
}

.policy.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

.policy.modal .modalContent {
    background-color: #fff;
    border-radius: 12px;
    padding: 30px 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
    min-width: 30%;
    max-width: 60%;
    max-height: 70%;
}

.policy.modal .modalContent .modalBody {
    overflow-y: auto;
    padding: 0 20px 0 0;
}

.policy.modal .modalContent .modalBody .modalText {
    white-space: pre-wrap;
}

.policy.modal .modalContent .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.policy.modal .modalContent .modalHeader h4 {
    font-size: 20px;
}

.policy.modal .modalContent .modalHeader button {
    border: 1px solid #000;
    border-radius: 6px;
    padding: 2px 6px;
    cursor: pointer;
    font-weight: bold;
    font-style: 20px;
}

.policy.modal .modalContent .policy{
    margin: 0 auto;
}

form {
    display: flex;
    justify-content: center;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* XXL */
@media (max-width: 1400px) {}

/* XL  */
@media (max-width: 1200px) {}

/* LG */
@media (max-width: 992px) {}

/* MD */
@media (max-width: 768px) {
    .policy.modal .modalContent {
        min-width: 90%;
    }
}

/* SM */
@media (max-width: 576px) {

    .form,
    .form .logoLink img {
        width: 80%;
    }
}