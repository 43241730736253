.dashboardHome {
    padding: 4rem 3rem;
    width: 100%;
    background-color: #fff;
}

.dashboardHome .dashboardHomeTitle {
    text-align: center;
    margin-bottom: 40px;
}

.dashboardHome .topicCards {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

@media (max-width: 1200px) {
    .dashboardHome .topicCards {
        grid-template-columns: auto auto;
    }
}

@media (max-width: 768px) {
    .dashboardHome {
        padding: 2rem;
    }

    .dashboardHome .topicCards {
        grid-template-columns: auto;
    }
}