.admin {
    padding: 3rem;
    width: calc(100% - 52px);
    display: flex;
    flex-direction: column;
    gap: 5rem;
    background-color: #fff;
}

.admin .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #333;
}

.admin .iconBtnText {
    border: 1px solid #333;
    border-radius: 6px;
    background-color: #fff;
    padding: 8px 16px;
    cursor: pointer;
}

.admin .iconBtnText i {
    margin-right: 8px;
}

.ReactModal__Content .inputWithLabel {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 6px;
    width: 100%;
}

.ReactModal__Content div input,
.ReactModal__Content div textarea {
    padding: 6px 20px;
    border: 2px solid var(--darkGray);
    border-radius: 12px;
    font-size: 16px;
    outline: none;
    width: 100%;
}

.ReactModal__Content div textarea {
    resize: none;
    height: 10em;
}

.ReactModal__Content .confirmModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.ReactModal__Content .confirmModal i {
    font-size: 26px;
    color: orange;
}

@media (max-width: 768px) {
    .admin {
        padding: 1.2rem;
    }
}