.achievements {
    width: 100%;
}

.achievements h1 {
    text-align: center;
    padding-top: 10rem;
    margin-bottom: 50px;
}

.achievements img {
    width: 100%;
    height: 700px;
    border-radius: 12px;
}

.achievements .swiperDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.achievements .swiper {
    border-radius: 12px;
    min-width: 1700px;
    height: 700px;
}

.achievements .swiper-pagination-bullet {
    background-color: #fff;
    opacity: 1;
}

.achievements .swiper-pagination-bullet-active {
    background-color: rgb(39, 163, 205);
}

@media (max-width: 1800px) {
    .achievements .swiper {
        min-width: 1500px;
        height: 600px;
    }

    .achievements img {
        height: 600px;
    }
}

@media (max-width: 1600px) {
    .achievements .swiper {
        min-width: 1400px;
        height: 560;
    }

    .achievements img {
        height: 560;
    }
}

@media (max-width: 1400px) {
    .achievements .swiper {
        min-width: 1200px;
        height: 480px;
    }

    .achievements img {
        height: 480px;
    }
}

@media (max-width: 1200px) {
    .achievements .swiper {
        min-width: 500px;
        max-width: 500px;
        height: 700px;
    }

    .achievements img {
        height: 700px;
    }
}

@media (max-width: 992px) {
    .achievements {
        padding: 0 2rem;
    }

    .achievements h1 {
        padding-top: 8rem;
    }
}

@media (max-width: 768px) {
    .achievements img {
        height: 560px;
    }
    
    .achievements .swiper {
        min-width: 400px;
        max-width: 400px;
        height: 560px;
    }
}

@media (max-width: 500px) {
    .achievements img {
        height: 450px;
    }

    .achievements .swiper {
        min-width: 330px;
        max-width: 330px;
        height: 450px;
    }
}