.navbar {
    display: flex;
    align-items: center;
    padding: 18px 0;
    border-bottom: 1px solid var(--darkGray);
    box-shadow: 1px 3px 5px var(--darkGray);
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 9999;
}

.navbar .logoWrapper,
.navbar .logoWrapper img {
    width: 124px;
    display: flex;
}

.navbar .navbarItems {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.navbar .menu ul {
    list-style: none;
    display: flex;
    gap: 50px;
    cursor: pointer;
    font-size: 17px;
}

.navbar .menu .menuItem::after {
    display: block;
    content: '';
    border-bottom: 2px solid var(--black);
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}

.navbar .menu .menuItem:hover::after {
    transform: scaleX(1);
}

.navbar .btns {
    display: flex;
    gap: 20px;
}

.navbar .mobileMenuBtnDiv {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 30px;
}

.navbar .mobileMenuBtn {
    display: none;
    background: transparent;
    cursor: pointer;
    color: var(--black);
    border: 2px solid var(--black);
    border-radius: 6px;
    padding: 4px 8px;
    margin-right: 20px;
}

@media (max-width: 1200px) {
    .navbar {
        align-items: flex-start;
    }

    .navbar .mobileMenuBtn {
        display: block;
    }

    .navbar:not(.mobileNavbarActive) .menu,
    .navbar:not(.mobileNavbarActive) .btns {
        display: none;
    }

    .navbar .navbarItems {
        flex-direction: column;
    }

    .navbar .menu ul {
        flex-direction: column;
        margin: 48px 0;
        text-align: center;
        gap: 40px;
    }
}