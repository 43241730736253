.usersTable {
    width: 100%;
}

.header .filterDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}

.header select {
    padding: 6px 12px;
    border: 2px solid var(--darkGray);
    border-radius: 12px;
    outline: none;
}

.usersTable .header .searchDiv {
    position: relative;
}

.usersTable .header .searchDiv input {
    width: 100%;
    top: 0;
    left: 0;
    padding: 6px 36px 6px 8px;
    border: 2px solid var(--darkGray);
    border-radius: 12px;
    outline: none;
}

.usersTable .header .searchDiv button {
    position: absolute;
    right: 6px;
    top: 3px;
    padding: 4px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.usersTable .tableWrapper {
    overflow-x: auto;
    border: 1px solid rgb(194, 194, 194);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.usersTable .operations {
    display: flex;
}

.usersTable .activateBtn {
    color: #009879;
}

.usersTable .deactivateBtn {
    color: salmon;
}

.usersTable .iconBtn:not(:first-child) {
    margin-left: 10px;
}

.usersTable table {
    width: 100%;
    box-shadow: 0 0 12px #b0b0b0;
    border-radius: 8px;
}

.usersTable table thead {
    background-color: var(--teal);
    color: #fff;
    text-align: left;
}

.usersTable table td,
.usersTable table th {
    padding: 12px 15px;
    min-width: 300px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .usersTable table td:first-child,
.usersTable table th:first-child{
    min-width: 70px;
    max-width: 70px;
} */

.usersTable table td:last-child,
.usersTable table th:last-child {
    min-width: 100px;
    max-width: 100px;
}

.usersTable tbody tr {
    border-bottom: 1px solid #dddddd;
}

.usersTable tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.usersTable tbody tr:last-of-type {
    border-bottom: 2px solid var(--teal);
}

.usersTable tbody tr:hover {
    background-color: #e3e3e3;
}

@media (max-width: 768px) {
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .header .filterDiv {
        width: 100%;
        justify-content: space-between;
    }
}