.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 10rem; */
    /* margin: 10rem 0; */
    margin: 0 0 10rem 0;
    padding: 0 16%;
    overflow: hidden;
}

@media (max-width: 992px) {
    .home {
        padding: 0;
        /* gap: 8rem; */
        /* margin: 6rem 0; */
        margin: 0 0 6rem 0;
    }
}