.sidebar {
    background-color: var(--teal);
    height: 100vh !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
    box-shadow: 0px 0px 8px var(--teal);
    transition: all 1s ease;
    position: sticky;
    top: 0;
}

.sidebar.closed {
    position: sticky;
    width: fit-content;
    border-radius: 0;
    transition: all 1s ease;
}

.sidebar .sidebarContent {
    align-self: center;
}

.sidebar .sidebarContent ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sidebar .sidebarContent ul li {
    cursor: pointer;
    padding: 0.7rem 3rem;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 16px;
}

.sidebar .sidebarContent.closed li {
    padding: 1rem;
    justify-content: center;
}

.sidebar .sidebarContent.closed span {
    display: none;
}

.sidebar .sidebarContent ul a.active {
    background-color: #105275;
    border-radius: 12px;
}

.sidebar .siderbarToggleBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #fff;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    float: right;
    font-size: 20px;
}

.sidebar .logoutBtn {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 20px;
    padding: 0.5rem 1rem;
    align-self: center;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-bottom: 6rem;
}

@media (max-width: 768px) {
    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
    }
}