.sectionAccordion .lengthBadge {
    font-size: 14px;
    font-weight: normal;
    background-color: var(--darkGray);
    color: #fff;
    padding: 0 6px;
    border-radius: 8px;
    margin-left: 12px;
}

.sectionAccordion .sectionButtons {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.sectionAccordion .accordionTopic .topicButtons {
    float: right;
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
}

::selection {
    color: #fff;
    background: #6990F2;
}

.ReactModal__Content .fileInputBox {
    width: 100%;
    background: #fff;
    border-radius: 5px;
}

.ReactModal__Content .fileInputBox header {
    color: #6990F2;
    font-size: 27px;
    font-weight: 600;
    text-align: center;
}

.ReactModal__Content .fileInputBox form {
    height: fit-content;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 12px;
    border: 2px dashed var(--darkGray);
    padding: 1rem 0;
}

.ReactModal__Content .fileInputBox form :where(i, p) {
    color: var(--darkGray);
}
 
.ReactModal__Content .fileInputBox form i {
    font-size: 32px;
}

.ReactModal__Content .fileInputBox form p {
    font-size: 16px;
}

.ReactModal__Content .fileInputBox section .row {
    margin-bottom: 10px;
    background: #E9F0FF;
    list-style: none;
    padding: 15px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ReactModal__Content .fileInputBox section .row i {
    color: #6990F2;
    font-size: 30px;
}

.ReactModal__Content .fileInputBox section .details span {
    font-size: 14px;
}

.ReactModal__Content .fileInputBox .progressArea .row .content {
    width: 100%;
    margin-left: 15px;
}

.ReactModal__Content .fileInputBox .progressArea .details {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    justify-content: space-between;
}

.ReactModal__Content .fileInputBox .progressArea .content .progressBar {
    height: 6px;
    width: 100%;
    margin-bottom: 4px;
    background: #fff;
    border-radius: 30px;
}

.ReactModal__Content .fileInputBox .content .progressBar .progress {
    height: 100%;
    width: 0%;
    background: #6990F2;
    border-radius: inherit;
}

.ReactModal__Content .fileInputBox .uploadedArea {
    max-height: 232px;
    overflow-y: scroll;
}

.ReactModal__Content .fileInputBox .uploadedArea.onprogress {
    max-height: 150px;
}

.ReactModal__Content .fileInputBox .uploadedArea::-webkit-scrollbar {
    width: 0px;
}

.ReactModal__Content .fileInputBox .uploadedArea .row .content {
    display: flex;
    align-items: center;
}

.ReactModal__Content .fileInputBox .uploadedArea .row .details {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
}

.ReactModal__Content .fileInputBox .uploadedArea .row .details .size {
    color: #404040;
    font-size: 11px;
}

.ReactModal__Content .fileInputBox .uploadedArea i.fa-check {
    font-size: 16px;
}