.sectionCard {
    border: 1px solid #ececec;
    border-radius: 12px;
    box-shadow: 0px 0px 5px #b5b5b5;
    padding: 2rem 3rem;
    cursor: pointer;
    width: 620px;
    text-align: center;
    word-wrap: break-word;
    transition: all 0.4s ease;
    font-size: 17px;
}

.sectionCard:hover {
    box-shadow: 0px 0px 12px gray;
}

.sectionCard i {
    margin-right: 16px;
}

@media (max-width: 768px) {
    .sectionCard {
        width: 100%;
        min-width: 300px;
    }
}