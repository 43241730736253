.topicCard {
    border: 1px solid #b5b5b5;
    border-radius: 1rem;
    cursor: pointer;
    box-shadow: 0px 0px 5px #b5b5b5;
    transition: all 0.4s ease;
    background-color: #fff;
    width: 380px;
    height: 400px;
}

.topicCard:hover {
    box-shadow: 0px 0px 12px gray;
}

.topicCard img {
    width: 100%;
    height: 200px;
    border-radius: 1rem;
}

.topicCard .info {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    word-wrap: break-word;
    height: 200px;
}

.topicCard .info p {
    font-size: 15px;
}

.topicCard .info span {
    text-align: right;
    font-size: 14px;
    color: gray;
}

@media (max-width: 1500px) {
    .topicCard {
        width: 300px;
        height: 400px;
    }

    .topicCard img {
        height: auto;
    }

    .topicCard .info {
        height: 240px;
    }
}