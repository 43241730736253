.section {
    padding: 3rem;
    width: 100%;
    background-color: #fff;
}

.section .content {
    display: flex;
    align-items: start;
    justify-content: space-around;
    gap: 40px;
    
}

.section .topics ul,
.section .topics li {
    list-style: none;
    padding: 0;
}

.section .topics {
    border-radius: 12px;
    position: relative;
    width: 30%;
}

.section .topics h1 {
    margin-left: -32px;
    margin-bottom: 32px;
}

.section .activeTopic {
    width: 50%;
}

.section .topics li {
    padding: 20px 32px;
    position: relative;
    background-color: #e3e3e3;
    margin-bottom: 20px;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 3px 3px 3px #b5b5b5;
}

.section .topics li:hover {
    box-shadow: 3px 3px 8px #a2a2a2;
}

.section .topics li.active {
    background-color: var(--teal);
}

.section .topics li:before {
    content: '\25B6';
    width: 24px;
    height: 24px;
    line-height: 25px;
    font-size: 13px;
    text-indent: 2px;
    text-align: center;
    background: white;
    border: 1.5px solid var(--teal);
    box-shadow: 3px 3px 0px #162a2f;
    box-shadow: 3px 3px 0px #929292;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: -2px;
    color: salmon;
}

.section .topics li.active .title,
.section .topics li.active .description {
    color: white;
}

.section .topics li.active:before {
    color: var(--teal);
}

.section .topics .title {
    color: #2a2839;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 17px;
}

.section .topics .description {
    color: #4f4f4f;
    font-family: sans-serif;
    line-height: 1.5;
    margin-top: 0.4rem;
    font-size: 15px;
}

@media (max-width: 1600px) {
    .section .activeTopic {
        width: 60%;
    }
}

@media (max-width: 1200px) {

    .section .content {
        flex-direction: column;
    }

    .section .activeTopic {
        width: 100%;
    }

    .section .topics {
        margin: 0 auto;
        width: 100%;
    }

    .section .topics h1 {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .section {
        padding: 1.2rem;
    }

    .section .activeTopic .summary {
        padding: 1rem;
    }
}