.sections {
    padding: 3rem;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
}

.sections .sectionCards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.sections .inactiveUserAlert {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    text-align: center;
}

.sections .inactiveUserAlert .lock {
    font-size: 54px;
    color: salmon;
}

@media (max-width: 768px) {
    .sections {
        padding: 2rem;
        justify-content: flex-start;
    }
}