.forgotPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    gap: 40px;
    padding: 5rem;
}

@media (max-width: 576px) {
    .forgotPassword{
        padding: 3rem 0;
    }
}