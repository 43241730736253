.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 100%;
    gap: 5rem;
    padding: 3rem;
    background-color: #fff;
    margin-bottom: 250px;
}

.profile .userInfo {
    display: flex;
    gap: 20rem;
}

.profile .userInfo .both {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8rem;
}

.profile .userInfo .info h4 {
    margin-bottom: 8px;
}

.profile .userInfo input {
    padding: 12px 50px;
    border: 1px solid gray;
    background-color: #ececec;
    border-radius: 16px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 6px 6px 6px gray;
}

.profile .userInfo .iconInput {
    position: relative;
}

.profile .userInfo .iconInput i:not(.password) {
    position: absolute;
    top: 35%;
    left: 6%;
    cursor: pointer;
}

.profile .userInfo .iconInput .password {
    position: absolute;
    top: 35%;
    right: 4%;
    cursor: pointer;
}

.profile .userInfo input:focus {
    outline: none;
}

.profile .editBtn i,
.profile .save i {
    margin-right: 8px;
}

.profile .editBtn {
    background-color: #105275;
    border: none;
    padding: 12px 18px;
}

.profile .save {
    background-color: seagreen;
    border: none;
    padding: 12px 18px;
}

.profile input:not(:disabled) {
    background-color: #fff;
    border: 1px solid gray;
}

@media (max-width: 1200px) {
    .profile {
        text-align: center;
        gap: 3rem;
    }

    .profile .userInfo {
        flex-direction: column;
        gap: 3rem;
    }

    .profile .userInfo .both {
        gap: 3rem;
    }
}
