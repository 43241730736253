.introduction {
    width: 100%;
    padding-top: 10rem;
}

.introduction .content {
    width: 100%;
    padding: 2rem;
    border: 1px solid lightblue;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 80px;
}

.introduction .leftSide {
    z-index: 2;
    color: #000;
    width: 60%;
}

.introduction .leftSide .badgeText {
    padding: 4px 18px;
    background-color: rgba(7, 7, 7, 0.1);
    border-radius: 8px;
    display: inline-block;
}

.introduction .leftSide h3 {
    margin-top: 14px;
}

.introduction .leftSide p {
    margin-top: 18px;
    font-size: 18px;
}

.introduction .rightSide img {
    height: 200px;
}

@media (max-width: 1920px) {
    .introduction .rightSide img {
        height: 150px;
    }
}

@media (max-width: 1600px) {
    .introduction {
        height: fit-content;
        padding: 10rem 2rem 0 2rem;
    }

    .introduction .content {
        gap: 70px;
        flex-direction: column;
        padding: 2rem 1rem;
    }
    
    .introduction .leftSide {
        width: 80%;
    }
}

@media (max-width: 992px) {
    .introduction {
        padding: 8rem 2rem 0 2rem;
    }
}

@media (max-width: 500px) {
    .introduction .rightSide img {
        height: 110px;
    }
}