.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border-radius: 12px;
}

.videoNotFound {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 470px;
    gap: 20px;
    font-size: 20px;
}

@media (max-width: 792px) {
    .videoNotFound{
         min-height: 350px;
    }
}