.teamCard {
    border-radius: 12px;
    position: relative;
    max-height: 300px;
    max-width: 200px;
    cursor: pointer;
}

.teamCard img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    box-shadow: 7px 7px 7px gray;
}

.teamCard .badge {
    position: absolute;
    bottom: -20px;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 70%;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 8px 0;
    transition: all 0.6s ease;
    box-shadow: 7px 7px 7px gray;
}
.teamCard:hover img {
    scale: 1.03;
    transition: all 0.6s ease;
}

.teamCard:hover .badge {
    background-color: var(--teal);
    color: #fff;
}

.teamCard .badge span::after {
    display: block;
    content: '';
    border-bottom: solid 2px var(--white) !important;
    transform: scaleX(0);
    transition: transform 500ms ease-in-out;
}

.teamCard:hover .badge span::after {
    transform: scaleX(1);
}