.faq {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.faq h1 {
    text-align: center;
    padding-top: 10rem;
}

.faq .accordion:last-child .accordionBox {
    margin-bottom: 0;
}

@media (max-width: 992px) {
    .faq {
        padding: 0 2rem;
    }

    .faq h1 {
        padding-top: 8rem;
    }
}