.noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 60px;
}

.noData img {
    min-width: 150px;
    max-width: 150px;
}