.ourTeam {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 10rem 2rem 0 2rem;
}

.ourTeam h1 {
    text-align: center;
}

.ourTeam .teamCards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 120px;
}

.ourTeam .teamCards:not(:first-of-type) {
    margin-top: 50px;
}

@media (max-width: 992px) {
    .ourTeam {
        padding-top: 8rem 2rem 0 2rem;
    }

    .ourTeam .teamCards {
        gap: 100px;
    }
}