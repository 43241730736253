.pagination {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination .totalCounts {
  font-size: 15px;
}

.pagination .paginationPc button {
  color: var(--teal);
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid var(--gray);
  margin: 0 4px;
  border-radius: 8px;
}

.pagination .paginationPc button.active {
  background-color: var(--teal);
  color: var(--white);
  border: 1px solid var(--teal);
}

.pagination .paginationPc button:hover:not(.active) {
  background-color: var(--gray);
}

.pagination .showCount {
  display: flex;
  align-items: center;
}

.pagination .paginationMobile {
  display: none;
  align-items: center;
}

.pagination .showCount label,
.pagination .paginationMobile label {
  margin-right: 10px;
  font-size: 15px;
}

.pagination .showCount select,
.pagination .paginationMobile select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid var(-lightGray);
  padding: 8px;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  background-color: var(--white);
  cursor: pointer;
  min-width: 42px;
}

.pagination .showCount select:focus,
.pagination .paginationMobile select:focus {
  border-color: var(--black);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.pagination .showCount option,
.pagination .paginationMobile option {
  font-size: 16px;
  padding: 8px;
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;
}

.pagination .showCount option:checked,
.pagination .paginationMobile option:checked {
  background-color: var(--black);
  color: var(--white);
}

.pagination .showCount option:hover,
.pagination .paginationMobile option:hover {
  background-color: var(--black);
  color: var(--white);
}

@media (max-width: 920px) {
  .pagination .paginationMobile {
    display: flex;
  }

  .pagination .paginationPc {
    display: none;
  }
}