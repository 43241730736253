.contactBadge {
    padding: 1rem 1.4rem;
    border-radius: 12px;
    font-size: 20px;
    transition: all 0.5s ease;
    min-width: 310px;
}

.contactBadge:hover {
    color: #fff !important;
}

.contactBadge i {
    margin-right: 20px;
    font-size: 22px;
}

.contactBadge.discord {
    border: 1px solid #7289da;
    color: #7289da;
}

.contactBadge.discord:hover {
    background: #7289da;
}

.contactBadge.twitter {
    border: 1px solid #000;
    color: #000;
}

.contactBadge.twitter:hover {
    background: #000;
}

.contactBadge.email {
    border: 1px solid #3b5998;
    color: #3b5998;
}

.contactBadge.email:hover {
    background: #3b5998;
}

.contactBadge.instagram {
    border: 1px solid #C13584;
    color: #C13584;
}

.contactBadge.instagram:hover {
    background: #C13584;
}