.contact {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 10rem 2rem 0 2rem;
}

.contact h1 {
    text-align: center;
}

.contact .contactOptions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 100px;
}

@media (max-width: 1200px) {
    .contact .contactOptions {
        gap: 50px;
    }
}

@media (max-width: 992px) {
    .contact {
        padding-top: 8rem 2rem 0 2rem;
    }
}